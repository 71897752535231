import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subject, takeUntil } from 'rxjs';
import { ResourceService } from 'src/app/services/common/resource.service';
import { StorageKey, StorageService } from 'src/app/services/common/storage.service';
import { UserInfoService } from 'src/app/services/user-info.service';
import { UserService } from 'src/app/services/user.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  isLoggedIn: any = false;
  resource: any = {};
  isVisbileTagLink: boolean = false;

  logoURL: any = "assets/img/logo.png";
  brandName: any = "On Offer";
  organization_store_Id: any = "";

  private destroy$: Subject<void> = new Subject<void>();

  constructor(private storageService: StorageService,
    private router: Router,
    private location: Location,
    private userInfoService: UserInfoService,
    private userService: UserService,
    private resourceService: ResourceService) {
  }

  ngOnInit(): void {
    this.loadResoruce();
    this.resourceService.languageSubject.subscribe(response => {
      this.resource = response;
    });

    this.isLoggedIn = this.userInfoService.getLoginFlag();
    this.storageService.loginSubject.subscribe(message => {
      this.isLoggedIn = this.userInfoService.getLoginFlag();
    });

    // Subscribe to browser navigation (back/forward)
    this.location.subscribe(() => {
      this.setBrandDetail();
    });


    this.setBrandDetail();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setBrandDetail() {
    this.logoURL = "assets/img/logo.png";
    this.brandName = "On Offer";
    this.isVisbileTagLink = true;
    this.organization_store_Id = "";

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd), // Only handle NavigationEnd events
        takeUntil(this.destroy$) // Automatically unsubscribe when component is destroyed
      )
      .subscribe((event: NavigationEnd) => {
        if (this.router.url.includes("?")) {
          const queryStringParams = this.router.url
            .split('?')[1]
            .split('&')
            .map(keyValue => keyValue.split('='))
            .reduce<any>((params, [key, value]) => {
              params[key] = value;
              return params;
            }, {});

          if (queryStringParams?.organizationId) {
            if (this.organization_store_Id != queryStringParams?.organizationId) {
              this.userService.get_business_user_name(queryStringParams?.organizationId).subscribe(res => {
                if (res.data) {
                  this.logoURL = res.data.logo;
                  this.brandName = res.data.name;
                  this.isVisbileTagLink = false;
                }
                this.organization_store_Id = queryStringParams?.organizationId;
              });
            }
          }

          if (queryStringParams?.storeId) {
            if (this.organization_store_Id != queryStringParams?.storeId) {
              this.userService.get_store_user_name(queryStringParams?.storeId).subscribe(res => {
                if (res.data) {
                  this.logoURL = res.data.stoLogo;
                  this.brandName = res.data.stoName;
                  this.isVisbileTagLink = false;
                }
                this.organization_store_Id = queryStringParams?.storeId;
              });
            }
          }
        }
      });
  }

  loadResoruce() {
    this.resourceService.loadResoruce().subscribe(
      response => {
        this.resource = response;
      });
  }

  classApplied = false;
  toggleClass() {
    this.classApplied = !this.classApplied;
  }

  onClickMenu(menu: string) {
    this.toggleClass();
    this.router.navigate([menu]);
    this.setBrandDetail();
  }

  logout() {
    this.toggleClass();
    this.userInfoService.setToken();
    this.storageService.removeValue(StorageKey.authToken);
    this.storageService.removeValue(StorageKey.currentUser);
    this.storageService.removeValue(StorageKey.rememberMe);
    sessionStorage.removeItem('currentUser');
    this.userInfoService.setLoginFlag(false);
    this.storageService.loginSubject.next(false);
    this.router.navigate(['/']);
    this.setBrandDetail();
  }
}