<div class="navbar-area navbar-style-two" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
        <div class="container-fluid">
                <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">

                        <a class="navbar-brand" routerLink="/"><img [src]="logoURL" [alt]="brandName"></a>
                        <span *ngIf="isVisbileTagLink" class="logo-heading">{{resource.home_banner_heading}}</span>
                        <button class="navbar-toggler col-1" type="button" (click)="toggleClass()">
                                <span class="burger-menu">
                                        <span class="top-bar"></span>
                                        <span class="middle-bar"></span>
                                        <span class="bottom-bar"></span>
                                </span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                <!-- <form class="search-box">
                                        <label><i class='bx bx-search'></i></label>
                                        <input type="text" class="input-search"
                                                [placeholder]="resource.navbar_searchbar_placeholder">
                                </form> -->
                                <ul class="navbar-nav">
                                        <li class="nav-item"><a routerLink="/" href="javascript:void(0)" class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}"
                                                        (click)="onClickMenu('/')"><i class='bx bx-home'></i>
                                                        {{resource.home}}</a>
                                        </li>
                                        <li class="nav-item"><a routerLink="/deal" href="javascript:void(0)" class="nav-link"
                                                        routerLinkActive="active" (click)="onClickMenu('/deal')"><i
                                                                class='bx bx-store'></i>{{resource.all_deals}}</a>
                                        </li>
                                        <li class="nav-item" *ngIf="isLoggedIn">
                                                <a routerLink="/my-dashboard" href="javascript:void(0)" class="nav-link" routerLinkActive="active"
                                                        (click)="onClickMenu('/my-dashboard')"><i
                                                                class='bx bx-user'></i>
                                                        {{resource.dashboard}}</a>
                                        </li>
                                        <li class="nav-item"><a routerLink="/contact" href="javascript:void(0)" class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}"
                                                        (click)="onClickMenu('/contact')"><i class='bx bx-envelope'></i>
                                                        {{resource.contact_us}}</a></li>
                                </ul>
                        </div>
                </nav>
        </div>
</div>